import { addAmounts, areAmountsEqual, substractAmounts, zeroAmount, type Amount } from '@orus.eu/amount'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { Product } from '@orus.eu/product'
import {
  addMonthlyPremiums,
  addYearlyPremiums,
  allCyberGuarantees,
  allTdmiGuarantees,
  type PaymentRecurrence,
} from '@orus.eu/quote'
import { assertSuccess } from '@orus.eu/result'
import type { OrganizationType } from '@orus.eu/right-access-management'
import type { Language } from '@orus.eu/translations'
import type { PartialDimensionnedState } from '../dimension/abstract-dimension.js'
import type { quoteDimension } from '../dimension/aggregated-quote-dimension.js'
import type { paymentRecurrenceDimension } from '../dimension/common-dimensions.js'
import type { esRcphQuoteDimension } from '../dimension/index.js'
import { mrphQuoteV2Dimension } from '../dimension/mrph/index.js'
import { mrpwQuoteDimension } from '../dimension/mrpw/index.js'
import { mutaQuoteDimension } from '../dimension/muta/index.js'
import {
  localizeAmount,
  localizeRecurrence,
  type ProductInformationId,
} from '../dimension/quote-offer-information-dimension.js'
import { getRcdaInstallmentFeePerMonth, rcdaQuoteDimension } from '../dimension/rcda/index.js'
import { rcphQuoteV2Dimension } from '../dimension/rcph/index.js'
import { productSelectionDimensions } from '../product.js'

export type CoverageInvoicingItemInputState = PartialDimensionnedState<
  [
    typeof paymentRecurrenceDimension,
    ...typeof productSelectionDimensions,
    typeof mrphQuoteV2Dimension,
    typeof mrpwQuoteDimension,
    typeof mutaQuoteDimension,
    typeof rcphQuoteV2Dimension,
    typeof rcdaQuoteDimension,
    typeof quoteDimension,
    typeof esRcphQuoteDimension,
  ]
>

export type InvoicingAmounts = {
  premiumWithoutTaxes: Amount
  /**
   * The total amount of insurance taxes
   */
  insuranceTaxes: Amount
  /**
   * The French "contribution attentat", included in the insuranceTaxes field
   */
  terrorismTax: Amount
  /**
   * installment fees included in totalPremium
   */
  installmentFee: Amount
  assistanceVAT: Amount
  assistancePremiumWithoutTaxes: Amount
  /**
   * The total due amount for this item, should equal premiumWithoutTaxes + insuranceTaxes + assistanceVAT
   */
  totalPremium: Amount
  orusFee: Amount
}

export type ItemAmountsFactory = (params: {
  input: CoverageInvoicingItemInputState
  invoiceIndex: number
  organizationType: OrganizationType
}) => {
  /**
   * The amount without Orus Discount, we need this data for Hiscox reporting.
   * We don't have the information in the Wakam quote, but luckily we don't need it right now. If at some point we need it, we'll need to change the Wakam quote.
   */
  withoutOrusDiscount?: InvoicingAmounts
  withoutPartnerFees?: InvoicingAmounts | undefined
  final: InvoicingAmounts
}

const freeProductAmounts = {
  withoutOrusDiscount: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
  },
  withoutPartnerFees: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
  },
  final: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
  },
}

const esRcphAmountFactory: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, invoiceIndex }) => {
    const esRcphQuote = checkDefinedAndNotNull(input.esRcphQuote)
    const withoutOrusDiscount = esRcphQuote.monthly.withoutOrusDiscount
    const withoutPartneeFees = esRcphQuote.monthly.withoutPartnerFees
    const final = esRcphQuote.monthly.final

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: addAmounts(withoutOrusDiscount.total.exTax),
        insuranceTaxes: addAmounts(withoutOrusDiscount.total.tax),
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: addAmounts(
          invoiceIndex % 12 === 0
            ? withoutOrusDiscount.total.firstMonthTotal
            : withoutOrusDiscount.total.subsequentMonthsTotal,
        ),
        orusFee: addAmounts(withoutOrusDiscount.total.commission),
        installmentFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartneeFees
        ? {
            premiumWithoutTaxes: addAmounts(withoutPartneeFees.total.exTax),
            insuranceTaxes: addAmounts(withoutPartneeFees.total.tax),
            terrorismTax: zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: addAmounts(
              invoiceIndex % 12 === 0
                ? withoutPartneeFees.total.firstMonthTotal
                : withoutPartneeFees.total.subsequentMonthsTotal,
            ),
            orusFee: addAmounts(withoutPartneeFees.total.commission),
            installmentFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: final.total.exTax,
        insuranceTaxes: final.total.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: invoiceIndex % 12 === 0 ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
        orusFee: final.total.commission,
        installmentFee: zeroAmount,
      },
    }
  },
  yearly: ({ input }) => {
    const esRcphQuote = checkDefinedAndNotNull(input.esRcphQuote)
    const withoutOrusDiscount = esRcphQuote.yearly.withoutOrusDiscount
    const withoutPartnerFees = esRcphQuote.yearly.withoutPartnerFees
    const final = esRcphQuote.yearly.final

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
        insuranceTaxes: withoutOrusDiscount.total.tax,
        terrorismTax: zeroAmount,
        installmentFee: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: withoutOrusDiscount.total.total,
        orusFee: withoutOrusDiscount.total.commission,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: zeroAmount,
            installmentFee: zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: withoutPartnerFees.total.total,
            orusFee: withoutPartnerFees.total.commission,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: final.total.exTax,
        insuranceTaxes: final.total.tax,
        terrorismTax: zeroAmount,
        installmentFee: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: final.total.total,
        orusFee: final.total.commission,
      },
    }
  },
}

export const productInformationIdAmountFactories: Record<
  ProductInformationId,
  Record<PaymentRecurrence, ItemAmountsFactory>
> = {
  'rc-pro': {
    monthly: ({ input, invoiceIndex, organizationType }) =>
      rcphWithoutCyberAndTdmiAmountFactories.monthly({ input, invoiceIndex, organizationType }),
    yearly: ({ input, invoiceIndex, organizationType }) =>
      rcphWithoutCyberAndTdmiAmountFactories.yearly({ input, invoiceIndex, organizationType }),
  },

  'rcph-cyber': {
    monthly: ({ input, invoiceIndex, organizationType }) =>
      rcphCyberAmountFactories.monthly({ input, invoiceIndex, organizationType }),
    yearly: ({ input, invoiceIndex, organizationType }) =>
      rcphCyberAmountFactories.yearly({ input, invoiceIndex, organizationType }),
  },
  'rcph-tdmi': {
    monthly: ({ input, invoiceIndex, organizationType }) =>
      rcphTdmiAmountFactories.monthly({ input, invoiceIndex, organizationType }),
    yearly: ({ input, invoiceIndex, organizationType }) =>
      rcphTdmiAmountFactories.yearly({ input, invoiceIndex, organizationType }),
  },
  'rcph-pj': {
    monthly: () => freeProductAmounts,
    yearly: () => freeProductAmounts,
  },
  mrph: {
    monthly: ({
      input,
      invoiceIndex,
    }): {
      withoutOrusDiscount: InvoicingAmounts
      withoutPartnerFees?: InvoicingAmounts | undefined
      final: InvoicingAmounts
    } => {
      const mrphQuoteV2 = checkDefinedAndNotNull(input.mrphQuoteV2)

      const withoutOrusDiscount = mrphQuoteV2.monthly.withoutOrusDiscount

      const withoutPartnerFees = mrphQuoteV2.monthly.withoutPartnerFees

      const final = mrphQuoteV2.monthly.final

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: invoiceIndex % 12 === 0 ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
          assistanceVAT: withoutOrusDiscount.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium:
            invoiceIndex % 12 === 0
              ? withoutOrusDiscount.total.firstMonthTotal
              : withoutOrusDiscount.total.subsequentMonthsTotal,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: invoiceIndex % 12 === 0 ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
              assistanceVAT: withoutPartnerFees.options.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.options.assistance?.premium.exTax ?? zeroAmount,
              totalPremium:
                invoiceIndex % 12 === 0
                  ? withoutPartnerFees.total.firstMonthTotal
                  : withoutPartnerFees.total.subsequentMonthsTotal,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: invoiceIndex % 12 === 0 ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
          assistanceVAT: final.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: invoiceIndex % 12 === 0 ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
          orusFee: final.total.commission,
          installmentFee: zeroAmount,
        },
      }
    },
    yearly: ({
      input,
    }): {
      withoutOrusDiscount: InvoicingAmounts
      withoutPartnerFees?: InvoicingAmounts | undefined
      final: InvoicingAmounts
    } => {
      const mrphQuoteV2 = checkDefinedAndNotNull(input.mrphQuoteV2)

      const withoutOrusDiscount = mrphQuoteV2.yearly.withoutOrusDiscount

      const withoutPartnerFees = mrphQuoteV2.yearly.withoutPartnerFees

      const final = mrphQuoteV2.yearly.final

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
          assistanceVAT: withoutOrusDiscount.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: withoutOrusDiscount.total.total,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
              assistanceVAT: withoutPartnerFees.options.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.options.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: withoutPartnerFees.total.total,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
          assistanceVAT: final.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: final.total.total,
          orusFee: final.total.commission,
          installmentFee: zeroAmount,
        },
      }
    },
  },
  mrpw: {
    monthly: ({ input, invoiceIndex, organizationType }) =>
      mrpwAmountFactories.monthly({ input, invoiceIndex, organizationType }),
    yearly: ({ input, invoiceIndex, organizationType }) =>
      mrpwAmountFactories.yearly({ input, invoiceIndex, organizationType }),
  },
  muta: {
    monthly: ({ input, invoiceIndex }) => {
      const mutaQuote = checkDefinedAndNotNull(input.mutaQuote)
      const withoutOrusDiscount = mutaQuote.monthly.withoutOrusDiscount
      const withoutPartnerFees = mutaQuote.monthly.withoutPartnerFees
      const final = mutaQuote.monthly.final

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: withoutOrusDiscount.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium:
            invoiceIndex % 12 === 0
              ? withoutOrusDiscount.total.firstMonthTotal
              : withoutOrusDiscount.total.subsequentMonthsTotal,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: zeroAmount,
              assistanceVAT: withoutPartnerFees.guarantees.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.guarantees.assistance?.premium.exTax ?? zeroAmount,
              totalPremium:
                invoiceIndex % 12 === 0
                  ? withoutPartnerFees.total.firstMonthTotal
                  : withoutPartnerFees.total.subsequentMonthsTotal,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: final.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: invoiceIndex % 12 === 0 ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
          orusFee: final.total.commission,
          installmentFee: zeroAmount,
        },
      }
    },
    yearly: ({ input }) => {
      const mutaQuote = checkDefinedAndNotNull(input.mutaQuote)
      const withoutOrusDiscount = mutaQuote.yearly.withoutOrusDiscount
      const withoutPartnerFees = mutaQuote.yearly.withoutPartnerFees
      const final = mutaQuote.yearly.final

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: withoutOrusDiscount.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: withoutOrusDiscount.total.total,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: zeroAmount,
              assistanceVAT: withoutPartnerFees.guarantees.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.guarantees.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: withoutPartnerFees.total.total,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: final.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: final.total.total,
          orusFee: final.total.commission,
          installmentFee: zeroAmount,
        },
      }
    },
  },
  rcda: {
    monthly: ({ input, invoiceIndex }) => {
      const rcdaQuote = checkDefinedAndNotNull(input.rcdaQuote)
      const withoutOrusDiscount = rcdaQuote.monthly.withoutOrusDiscount
      const withoutPartneeFees = rcdaQuote.monthly.withoutPartnerFees
      const final = rcdaQuote.monthly.final

      const oneTime =
        invoiceIndex === 0 && rcdaQuote.oneTime?.final.options.historyTakeover?.active === true
          ? rcdaQuote.oneTime
          : undefined

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: addAmounts(
            withoutOrusDiscount.total.exTax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            withoutOrusDiscount.total.tax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            invoiceIndex % 12 === 0
              ? withoutOrusDiscount.total.firstMonthTotal
              : withoutOrusDiscount.total.subsequentMonthsTotal,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            withoutOrusDiscount.total.commission,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
          installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,
        },
        withoutPartnerFees: withoutPartneeFees
          ? {
              premiumWithoutTaxes: addAmounts(
                withoutPartneeFees.total.exTax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.exTax ?? zeroAmount,
              ),
              insuranceTaxes: addAmounts(
                withoutPartneeFees.total.tax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.tax ?? zeroAmount,
              ),
              terrorismTax: zeroAmount,
              assistanceVAT: zeroAmount,
              assistancePremiumWithoutTaxes: zeroAmount,
              totalPremium: addAmounts(
                invoiceIndex % 12 === 0
                  ? withoutPartneeFees.total.firstMonthTotal
                  : withoutPartneeFees.total.subsequentMonthsTotal,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.total ?? zeroAmount,
              ),
              orusFee: addAmounts(
                withoutPartneeFees.total.commission,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.commission ?? zeroAmount,
              ),
              installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: addAmounts(
            final.total.exTax,
            oneTime?.final.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            final.total.tax,
            oneTime?.final.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            invoiceIndex % 12 === 0 ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
            oneTime?.final.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            final.total.commission,
            oneTime?.final.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
          installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,
        },
      }
    },
    yearly: ({ input, invoiceIndex }) => {
      const rcdaQuote = checkDefinedAndNotNull(input.rcdaQuote)
      const withoutOrusDiscount = rcdaQuote.yearly.withoutOrusDiscount
      const withoutPartnerFees = rcdaQuote.yearly.withoutPartnerFees
      const final = rcdaQuote.yearly.final
      const oneTime =
        invoiceIndex === 0 && rcdaQuote.oneTime?.final.options.historyTakeover?.active === true
          ? rcdaQuote.oneTime
          : undefined

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: addAmounts(
            withoutOrusDiscount.total.exTax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            withoutOrusDiscount.total.tax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          installmentFee: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            withoutOrusDiscount.total.total,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            withoutOrusDiscount.total.commission,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: addAmounts(
                withoutPartnerFees.total.exTax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.exTax ?? zeroAmount,
              ),
              insuranceTaxes: addAmounts(
                withoutPartnerFees.total.tax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.tax ?? zeroAmount,
              ),
              terrorismTax: zeroAmount,
              installmentFee: zeroAmount,
              assistanceVAT: zeroAmount,
              assistancePremiumWithoutTaxes: zeroAmount,
              totalPremium: addAmounts(
                withoutPartnerFees.total.total,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.total ?? zeroAmount,
              ),
              orusFee: addAmounts(
                withoutPartnerFees.total.commission,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.commission ?? zeroAmount,
              ),
            }
          : undefined,
        final: {
          premiumWithoutTaxes: addAmounts(
            final.total.exTax,
            oneTime?.final.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            final.total.tax,
            oneTime?.final.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          installmentFee: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            final.total.total,
            oneTime?.final.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            final.total.commission,
            oneTime?.final.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
        },
      }
    },
  },
  // ProductInformationId exist here for presentation purpose only, and the real information is in the quote
  'rcda-rc-pro': {
    monthly: () => freeProductAmounts,
    yearly: () => freeProductAmounts,
  },
  restaurant: {
    monthly: ({ input, invoiceIndex, organizationType }) =>
      mrpwAmountFactories.monthly({ input, invoiceIndex, organizationType }),
    yearly: ({ input, invoiceIndex, organizationType }) =>
      mrpwAmountFactories.yearly({ input, invoiceIndex, organizationType }),
  },
  'es-rcph-pro': esRcphAmountFactory,
  'es-rcph-general': esRcphAmountFactory,
}

const mrpwAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, invoiceIndex }) => {
    const mrpwQuote = assertSuccess(checkDefinedAndNotNull(input.mrpwQuote))

    return {
      withoutPartnerFees: {
        premiumWithoutTaxes: mrpwQuote.monthlyBasePremium,
        insuranceTaxes: mrpwQuote.monthlyTaxes,
        terrorismTax: invoiceIndex % 12 === 0 ? mrpwQuote.terrorismTax : zeroAmount,
        assistanceVAT: mrpwQuote.monthlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.monthlyAssistanceBasePremium ?? zeroAmount,
        totalPremium:
          invoiceIndex % 12 === 0
            ? substractAmounts(
                mrpwQuote.firstMonthTotalPremium,
                mrpwQuote.monthlyPartnerManagementFeePremium ?? zeroAmount,
              )
            : substractAmounts(
                mrpwQuote.subsequentMonthsTotalPremium,
                mrpwQuote.monthlyPartnerManagementFeePremium ?? zeroAmount,
              ),
        orusFee: mrpwQuote.monthlyOrusFee,
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: mrpwQuote.monthlyBasePremium,
        insuranceTaxes: mrpwQuote.monthlyTaxes,
        terrorismTax: invoiceIndex % 12 === 0 ? mrpwQuote.terrorismTax : zeroAmount,
        assistanceVAT: mrpwQuote.monthlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.monthlyAssistanceBasePremium ?? zeroAmount,
        totalPremium:
          invoiceIndex % 12 === 0 ? mrpwQuote.firstMonthTotalPremium : mrpwQuote.subsequentMonthsTotalPremium,
        orusFee: mrpwQuote.monthlyOrusFee,
        installmentFee: zeroAmount,
      },
    }
  },
  yearly: ({ input }) => {
    const mrpwQuote = assertSuccess(checkDefinedAndNotNull(input.mrpwQuote))
    return {
      withoutPartnerFees: {
        premiumWithoutTaxes: mrpwQuote.yearlyBasePremium,
        insuranceTaxes: mrpwQuote.yearlyTaxes,
        terrorismTax: mrpwQuote.terrorismTax,
        assistanceVAT: mrpwQuote.yearlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.yearlyAssistanceBasePremium ?? zeroAmount,
        totalPremium: substractAmounts(
          mrpwQuote.yearlyTotalPremium,
          mrpwQuote.yearlyPartnerManagementFeePremium ?? zeroAmount,
        ),
        orusFee: mrpwQuote.yearlyOrusFee,
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: mrpwQuote.yearlyBasePremium,
        insuranceTaxes: mrpwQuote.yearlyTaxes,
        terrorismTax: mrpwQuote.terrorismTax,
        assistanceVAT: mrpwQuote.yearlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.yearlyAssistanceBasePremium ?? zeroAmount,
        totalPremium: addAmounts(mrpwQuote.yearlyTotalPremium, mrpwQuote.partnerApplicationFeePremium ?? zeroAmount),
        orusFee: mrpwQuote.yearlyOrusFee,
        installmentFee: zeroAmount,
      },
    }
  },
}

const rcphCyberAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 12 ? rcphQuoteV2.monthly.withoutOrusDiscount : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalCyberPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : invoiceIndex < 12
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalCyberPremium = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalCyberPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalCyberPremiumWithoutOrusDiscount.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremiumWithoutOrusDiscount.total,
        orusFee: totalCyberPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: totalCyberPremium.exTax,
        insuranceTaxes: totalCyberPremium.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremium.total,
        orusFee: totalCyberPremium.commission,
        installmentFee: zeroAmount,
      },
    }
  },
  yearly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 1 ? rcphQuoteV2.yearly.withoutOrusDiscount : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalCyberPremiumWithoutOrusDiscount = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : invoiceIndex < 1
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalCyberPremium = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalCyberPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalCyberPremiumWithoutOrusDiscount.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremiumWithoutOrusDiscount.total,
        orusFee: totalCyberPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: totalCyberPremium.exTax,
        insuranceTaxes: totalCyberPremium.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremium.total,
        orusFee: totalCyberPremium.commission,
        installmentFee: zeroAmount,
      },
    }
  },
}

const rcphTdmiAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 12 ? rcphQuoteV2.monthly.withoutOrusDiscount : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalTdmiPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : invoiceIndex < 12
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalTdmiPremium = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalTdmiPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalTdmiPremiumWithoutOrusDiscount.tax,
        terrorismTax: invoiceIndex % 12 === 0 ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium:
          invoiceIndex % 12 === 0
            ? addAmounts(totalTdmiPremiumWithoutOrusDiscount.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
            : totalTdmiPremiumWithoutOrusDiscount.total,
        orusFee: totalTdmiPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: totalTdmiPremium.exTax,
        insuranceTaxes: totalTdmiPremium.tax,
        terrorismTax: invoiceIndex % 12 === 0 ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium:
          invoiceIndex % 12 === 0
            ? addAmounts(totalTdmiPremium.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
            : totalTdmiPremium.total,
        orusFee: totalTdmiPremium.commission,
        installmentFee: zeroAmount,
      },
    }
  },
  yearly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 1 ? rcphQuoteV2.yearly.withoutOrusDiscount : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalTdmiPremiumWithoutOrusDiscount = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : invoiceIndex < 1
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalTdmiPremium = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalTdmiPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalTdmiPremiumWithoutOrusDiscount.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalTdmiPremiumWithoutOrusDiscount.total,
        orusFee: totalTdmiPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: totalTdmiPremium.exTax,
        insuranceTaxes: totalTdmiPremium.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalTdmiPremium.total,
        orusFee: totalTdmiPremium.commission,
        installmentFee: zeroAmount,
      },
    }
  },
}

const rcphWithoutCyberAndTdmiAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 12 ? rcphQuoteV2.monthly.withoutOrusDiscount : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalRcphPremiumWithoutOrusDiscount = applicableFinalWithoutOrusDiscount.total

    const totalTdmiPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const totalCyberPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : invoiceIndex < 12
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalRcphPremium = applicableFinal.total

    const totalTdmiPremium = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    const totalCyberPremium = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    const totalMonthlyRcphPremiumWithoutOrusDiscount =
      invoiceIndex % 12 === 0
        ? totalRcphPremiumWithoutOrusDiscount.firstMonthTotal
        : totalRcphPremiumWithoutOrusDiscount.subsequentMonthsTotal

    const totalMonthlyTdmiPremiumWithoutOrusDiscount =
      invoiceIndex % 12 === 0
        ? addAmounts(totalTdmiPremiumWithoutOrusDiscount.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
        : totalTdmiPremiumWithoutOrusDiscount.total

    const totalMonthlyCyberPremiumWithoutOrusDiscount = totalCyberPremiumWithoutOrusDiscount.total

    const totalMonthlyRcphPremium =
      invoiceIndex % 12 === 0 ? totalRcphPremium.firstMonthTotal : totalRcphPremium.subsequentMonthsTotal

    const totalMonthlyTdmiPremium =
      invoiceIndex % 12 === 0
        ? addAmounts(totalTdmiPremium.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
        : totalTdmiPremium.total

    const totalMonthlyCyberPremium = totalCyberPremium.total

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.exTax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.exTax, totalCyberPremiumWithoutOrusDiscount.exTax),
        ),
        insuranceTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.tax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.tax, totalCyberPremiumWithoutOrusDiscount.tax),
        ),
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalMonthlyRcphPremiumWithoutOrusDiscount,
          addAmounts(totalMonthlyTdmiPremiumWithoutOrusDiscount, totalMonthlyCyberPremiumWithoutOrusDiscount),
        ),
        orusFee: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.commission,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.commission, totalCyberPremiumWithoutOrusDiscount.commission),
        ),
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremium.exTax,
          addAmounts(totalTdmiPremium.exTax, totalCyberPremium.exTax),
        ),
        insuranceTaxes: substractAmounts(totalRcphPremium.tax, addAmounts(totalTdmiPremium.tax, totalCyberPremium.tax)),
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalMonthlyRcphPremium,
          addAmounts(totalMonthlyTdmiPremium, totalMonthlyCyberPremium),
        ),
        orusFee: substractAmounts(
          totalRcphPremium.commission,
          addAmounts(totalTdmiPremium.commission, totalCyberPremium.commission),
        ),
        installmentFee: zeroAmount,
      },
    }
  },
  yearly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 1 ? rcphQuoteV2.yearly.withoutOrusDiscount : rcphQuoteV2.yearly.withoutAnyDiscount

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : invoiceIndex < 1
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalRcphPremiumWithoutOrusDiscount = applicableFinalWithoutOrusDiscount.total

    const totalTdmiPremiumWithoutOrusDiscount = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const totalCyberPremiumWithoutOrusDiscount = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const totalRcphPremium = applicableFinal.total

    const totalTdmiPremium = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    const totalCyberPremium = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.exTax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.exTax, totalCyberPremiumWithoutOrusDiscount.exTax),
        ),
        insuranceTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.tax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.tax, totalCyberPremiumWithoutOrusDiscount.tax),
        ),
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.total,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.total, totalCyberPremiumWithoutOrusDiscount.total),
        ),
        orusFee: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.commission,
          addAmounts(totalRcphPremiumWithoutOrusDiscount.commission, totalRcphPremiumWithoutOrusDiscount.commission),
        ),
        installmentFee: zeroAmount,
      },
      final: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremium.exTax,
          addAmounts(totalTdmiPremium.exTax, totalCyberPremium.exTax),
        ),
        insuranceTaxes: substractAmounts(totalRcphPremium.tax, addAmounts(totalTdmiPremium.tax, totalCyberPremium.tax)),
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalRcphPremium.total,
          addAmounts(totalTdmiPremium.total, totalCyberPremium.total),
        ),
        orusFee: substractAmounts(
          totalRcphPremium.commission,
          addAmounts(totalRcphPremium.commission, totalRcphPremium.commission),
        ),
        installmentFee: zeroAmount,
      },
    }
  },
}

const rcphAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)

    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 12 ? rcphQuoteV2.monthly.withoutOrusDiscount : rcphQuoteV2.monthly.withoutAnyDiscount

    const withoutPartnerFees = rcphQuoteV2.monthly.withoutPartnerFees

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : invoiceIndex < 12
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: applicableFinalWithoutOrusDiscount.total.exTax,
        insuranceTaxes: applicableFinalWithoutOrusDiscount.total.tax,
        terrorismTax: invoiceIndex % 12 === 0 ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium:
          invoiceIndex % 12 === 0
            ? applicableFinalWithoutOrusDiscount.total.firstMonthTotal
            : applicableFinalWithoutOrusDiscount.total.subsequentMonthsTotal,
        orusFee: applicableFinalWithoutOrusDiscount.total.commission,
        installmentFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: invoiceIndex % 12 === 0 ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium:
              invoiceIndex % 12 === 0
                ? withoutPartnerFees.total.firstMonthTotal
                : withoutPartnerFees.total.subsequentMonthsTotal,
            orusFee: withoutPartnerFees.total.commission,
            installmentFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: applicableFinal.total.exTax,
        insuranceTaxes: applicableFinal.total.tax,
        terrorismTax: invoiceIndex % 12 === 0 ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium:
          invoiceIndex % 12 === 0 ? applicableFinal.total.firstMonthTotal : applicableFinal.total.subsequentMonthsTotal,
        orusFee: applicableFinal.total.commission,
        installmentFee: zeroAmount,
      },
    }
  },
  yearly: ({ input, invoiceIndex, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount =
      invoiceIndex < 1 ? rcphQuoteV2.yearly.withoutOrusDiscount : rcphQuoteV2.yearly.withoutAnyDiscount

    const withoutPartnerFees = rcphQuoteV2.yearly.withoutPartnerFees

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : invoiceIndex < 1
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: applicableFinalWithoutOrusDiscount.total.exTax,
        insuranceTaxes: applicableFinalWithoutOrusDiscount.total.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: applicableFinalWithoutOrusDiscount.total.total,
        orusFee: applicableFinalWithoutOrusDiscount.total.commission,
        installmentFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: withoutPartnerFees.total.total,
            orusFee: withoutPartnerFees.total.commission,
            installmentFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: applicableFinal.total.exTax,
        insuranceTaxes: applicableFinal.total.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: applicableFinal.total.total,
        orusFee: applicableFinal.total.commission,
        installmentFee: zeroAmount,
      },
    }
  },
}
export const productAmountFactories: Record<Product, Record<PaymentRecurrence, ItemAmountsFactory>> = {
  mrph: productInformationIdAmountFactories.mrph,
  mrpw: productInformationIdAmountFactories.mrpw,
  rcph: rcphAmountFactories,
  muta: productInformationIdAmountFactories.muta,
  rcda: productInformationIdAmountFactories.rcda,
  'es-rcph': esRcphAmountFactory,
}

type GetAmountLabelParams = {
  productType:
    | { type: 'product'; product: Product }
    | { type: 'productInformationId'; productInformationId: ProductInformationId }
  state: CoverageInvoicingItemInputState
  organizationType: OrganizationType
}

export type GetAmountLabel = { price: string; recurrence: string | undefined } | undefined

export function getAmountLabel(
  { productType, state, organizationType }: GetAmountLabelParams,
  language: Language,
): GetAmountLabel {
  const amount = getEffectiveTotalAmount({ productType, state, organizationType })
  if (!amount) return

  const price = localizeAmount(
    {
      amount,
      type: checkDefinedAndNotNull(state.paymentRecurrence),
    },
    language,
  )

  if (typeof amount !== 'string' && !areAmountsEqual(amount, zeroAmount)) {
    const recurrence = localizeRecurrence(checkDefinedAndNotNull(state.paymentRecurrence), language)

    return { price, recurrence }
  }

  return { price, recurrence: undefined }
}

function getEffectiveTotalAmount({ productType, state, organizationType }: GetAmountLabelParams): Amount | undefined {
  //We display the 2nd month, without one time taxes as terrorism tax.
  const monthlyInvoiceIndex = 1

  //We display the 2nd year for rcda, without one time option as history takeover and first year otherwise.
  const yearlyInvoiceIndex =
    (productType.type === 'product' && productType.product === 'rcda') ||
    (productType.type === 'productInformationId' && productType.productInformationId === 'rcda')
      ? 1
      : 0

  if (productType.type === 'product') {
    if (productType.product === 'rcph' && !state.rcphQuoteV2) return
    if (productType.product === 'mrpw' && !state.mrpwQuote) return
    if (productType.product === 'mrph' && !state.mrphQuoteV2) return
    if (productType.product === 'muta' && !state.mutaQuote) return
    if (productType.product === 'rcda' && !state.rcdaQuote) return

    return state.paymentRecurrence === 'monthly'
      ? productAmountFactories[productType.product].monthly({
          input: state,
          invoiceIndex: monthlyInvoiceIndex,
          organizationType,
        }).final.totalPremium
      : productAmountFactories[productType.product].yearly({
          input: state,
          invoiceIndex: yearlyInvoiceIndex,
          organizationType,
        }).final.totalPremium
  }

  if (
    (productType.productInformationId === 'rc-pro' ||
      productType.productInformationId === 'rcph-cyber' ||
      productType.productInformationId === 'rcph-pj' ||
      productType.productInformationId === 'rcph-tdmi') &&
    !state.rcphQuoteV2
  )
    return

  if (
    (productType.productInformationId === 'mrpw' || productType.productInformationId === 'restaurant') &&
    !state.mrpwQuote
  )
    return
  if (productType.productInformationId === 'mrph' && !state.mrphQuoteV2) return
  if (productType.productInformationId === 'muta' && !state.mutaQuote) return
  if (
    (productType.productInformationId === 'rcda' || productType.productInformationId === 'rcda-rc-pro') &&
    !state.rcdaQuote
  )
    return

  return state.paymentRecurrence === 'monthly'
    ? productInformationIdAmountFactories[productType.productInformationId].monthly({
        input: state,
        invoiceIndex: monthlyInvoiceIndex,
        organizationType,
      }).final.totalPremium
    : productInformationIdAmountFactories[productType.productInformationId].yearly({
        input: state,
        invoiceIndex: yearlyInvoiceIndex,
        organizationType,
      }).final.totalPremium
}
